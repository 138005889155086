import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    margin-top: 19px;
    flex-direction: column;
    padding: 0 17px 0 17px;
  `};
`;

export const Sl = styled(Wrapper)`
  ${() => css`
    margin-top: 8px;
    flex-direction: column;

    div:last-child {
      margin-top: 5px;
    }
  `};
`;
