import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { layoutMargin } from '@trader/constants';

export const Content = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    padding: ${layoutMargin}px;
    overflow: auto;
  `};
`;

export const Item = styled(Wrapper)`
  ${({ theme }) => css`
    height: 40px;
    margin-bottom: 5px;
    align-items: center;
    padding: 0 ${layoutMargin}px;
    background: ${theme.palette.background.default};

    > span {
      margin-right: 10px;
      font-size: ${theme.typography.xl.fontSize};
      color: ${theme.palette.text.secondary};
    }
  `};
`;

export const ItemValue = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.base.fontSize};
  `};
`;
