import styled, { css } from 'styled-components';
import { Slider as MuiSlider } from '@mui/material';

import { Typography } from '../typography';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `};
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    padding: 11px 17px 8px 0;
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    width: 100%;
  `};
`;

export const Slider = styled(MuiSlider)`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.palette.common.black};

    .MuiSlider-rail {
      height: 5px;
      background: ${theme.palette.grey[800]};
    }

    .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: 3px solid #a8aab5;
    }

    .MuiSlider-track {
      color: ${theme.palette.grey[800]};
      border: none;
      height: 5px;
    }
  `};
`;
