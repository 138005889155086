import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { minQuantity } from '@trader/constants';
import { Controller } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';

import { IBackTestingInitialValues } from './index';

export const Quantity = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const symbol = backTesting.getSymbol();

  const { setValue } = useFormContext<IBackTestingInitialValues>();

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  useEffect(() => {
    if (!symbol) {
      return setValue('quantity', 0);
    }

    if (instrument) {
      setValue('quantity', minQuantity);
    }
  }, [symbol, instrument?.minOrderSize]);

  return (
    <Controller
      disabled={!symbol}
      type='textField'
      label={translate('COMMON.LABELS.QUANTITY')}
      name='quantity'
    />
  );
});
