import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { TInstrumentEntity, TOrderMetricEntity, useMst } from '@trader/store';
import { devLoggerService, useI18next } from '@trader/services';
import { TPlaceOrderSide } from '@trader/types';
import { checkOnTickSize } from '@trader/utils';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from './useGetAdapterDiff';

type TCreateOrder = (
  id: string,
  side: TPlaceOrderSide,
  price: number,
  quantity: number,
  tp: string,
  sl: string
) => void;

interface IOrder {
  key: string;
  ref: IOrderLineAdapter | undefined;
}

const orderLineLength = 30;
const orders = new Map<string, IOrder>();

export const useOrders = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const { translate } = useI18next();
  const theme = useTheme();
  const { sideColor, typeColor, tooltip, manuallyTitle } =
    useGetAdapterDiff('Order');

  const ordersMetrics =
    store.entities.ordersMetrics.getAll<TOrderMetricEntity>();
  const strips = Array.from(store.pages.muliBands.strips.values());
  const symbol =
    store.pages.muliBands.symbol ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const symbolOrdersMetrics = ordersMetrics.filter(
    metric => metric.symbol === symbol
  );

  const createLimitOrder: TCreateOrder = (
    id,
    side,
    price,
    quantity,
    tp,
    sl
  ) => {
    const ref = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setCancelTooltip('Cancel order')
      .setText(`${side} Limit ${tp} ${sl}`)
      .setBodyTextColor(sideColor(side))
      .setBodyBorderColor(typeColor(id, side))
      .setTooltip(tooltip(id))
      .setBodyBackgroundColor(theme.palette.white.main)
      .setQuantity(`${quantity}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setLineColor(typeColor(id, side))
      .setLineLength(orderLineLength)
      .setCancelButtonBorderColor(theme.palette.tab.light)
      .setCancelButtonBackgroundColor(theme.palette.tab.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .onCancel(async function () {
        try {
          const order =
            store.entities.ordersMetrics.get<TOrderMetricEntity>(id);

          if (order) {
            await order.cancelOrderAsync.run(id);
            this.remove();
            orders.delete(id);
            const strip = strips.find(
              s => Number(s.orderId) === Number(order.orderId)
            );
            if (strip) {
              store.pages.muliBands.updateStripOrder(strip.id, null);
            }
          }
        } catch (e) {
          devLoggerService.error('onCancel called', e);
        }
      })
      .onMove(async function () {
        const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);
        const instrument = store.entities.instruments.get<TInstrumentEntity>(
          order.symbol
        );

        if (checkOnTickSize(this.getPrice(), instrument.tickSize)) {
          this.setPrice(order.limitPrice || order.stopPrice);
          return store.notifications.add({
            message: translate('COMMON.ERRORS.TICK_SIZE', {
              tickSize: instrument.tickSize,
            }),
            options: {
              variant: 'warning',
            },
          });
        }

        try {
          const band = strips.find(s => s.orderId === Number(id));

          if (band) {
            await store.pages.muliBands.editMuliBandsOrderAsync.run({
              bandId: band.id,
              price: Number(this.getPrice()),
            });
          } else {
            await order.editOrderAsync.run({
              orderId: id,
              body: {
                symbol: order.symbol,
                type: order.type,
                side: order.side,
                quantity: order.quantity,
                price: Number(this.getPrice()),
                takeProfit: order.takeProfit || undefined,
                stopLoss: order.stopLoss || undefined,
              },
            });
          }

          this.setTooltip(manuallyTitle);
          this.setBodyBorderColor(theme.palette.tab.light);
          this.setLineColor(theme.palette.tab.light);
        } catch (error) {
          this.setPrice(order.limitPrice || order.stopPrice);
        }
      })
      .setPrice(price);

    orders.set(id, {
      ...orders.get(id),
      key: id,
      ref,
    });
  };

  const clearOrders = useCallback(() => {
    orders.clear();
  }, []);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      if (!strips.length) {
        return;
      }

      symbolOrdersMetrics.forEach(metric => {
        if (!metric) {
          return;
        }

        const price = (metric.limitPrice || metric.stopPrice) as number;

        if (!orders.get(metric.orderId)) {
          const tp = metric.takeProfit ? `TP: ${metric.takeProfit}` : '';
          const sl = metric.stopLoss ? `SL: ${metric.stopLoss}` : '';

          createLimitOrder(
            metric.orderId,
            metric.side,
            price,
            metric.quantity,
            tp,
            sl
          );
        } else {
          const orderRef = orders.get(metric.orderId)?.ref;

          orderRef?.setPrice(price);
          orderRef?.setTooltip(tooltip(metric.orderId));
          orderRef?.setBodyBorderColor(typeColor(metric.orderId, metric.side));
          orderRef?.setLineColor(typeColor(metric.orderId, metric.side));
        }
      });
    });
  }, [JSON.stringify(strips), JSON.stringify(ordersMetrics)]);

  // delete order
  useEffect(() => {
    Array.from(orders.values()).forEach((order: IOrder) => {
      const matchedOrder = store.entities.ordersMetrics.get<TOrderMetricEntity>(
        order.key
      );
      if (!matchedOrder) {
        orders.get(order.key)?.ref?.remove();
        orders.delete(order.key);
      }
    });
  }, [ordersMetrics.length]);

  return {
    clearOrders,
  };
};
