import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, TOptions } from '@trader/types';
import { Autocomplete } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import * as Styled from './styled';

const uniqueMultipliers = new Set<string>();

export const MultiplierFilter: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const [options, setOptions] = useState<TOptions>([]);

  const backTesting = store.pages.muliBands.backTesting;

  const value = backTesting.selectedMultiplierOption
    ? [backTesting.selectedMultiplierOption]
    : [];

  const listOfMultipliers = backTesting.getAllMultipliers();

  const canCalculateMultipliers = Boolean(
    backTesting.id && !backTesting.isPending
  );

  const onChange = (event: IChangeFieldEvent<TOptions>) => {
    backTesting.runInAction(() => {
      backTesting.selectedMultiplierOption = event.target.value[0];
    });
    const multiplier = Number(event.target.value[0].value);

    if (multiplier) {
      store.pages.muliBands.runInAction(() => {
        store.pages.muliBands.topMultiplier = multiplier;
        store.pages.muliBands.bottomMultiplier = multiplier;
      });
    }
  };

  useEffect(() => {
    if (canCalculateMultipliers) {
      listOfMultipliers.forEach(({ multiplier }) => {
        uniqueMultipliers.add(multiplier.toString());
      });

      const multiplierOptions: TOptions = Array.from(uniqueMultipliers).map(
        m => ({
          title: m,
          value: m,
        })
      );

      setOptions(multiplierOptions);
    }
    return () => {
      uniqueMultipliers.clear();
    };
  }, [canCalculateMultipliers]);

  return (
    <Styled.Content>
      <Autocomplete
        shouldHideControlInfo
        placeholder={translate('MULI_BANDS.MULTIPLIER')}
        value={value}
        options={options}
        shouldHideInput
        withCheckbox={false}
        onChange={onChange}
        size='small'
      />
    </Styled.Content>
  );
});
