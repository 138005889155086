import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';

import {
  CircularProgressBar,
  ExpandedContent,
  Progress,
  Tabs,
  THeightMode,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';

import { ListOfTrades, PerformanceSummary } from './components';

import * as Styled from './styled';
import { ListOfMultipliers } from './components/listOfMultipliers';

const defaultHeight = 250;
const minHeight = 70;
const maxHeight = 600;

interface IState {
  height: number;
  isFullScreen: boolean;
}

const initialState = {
  height: defaultHeight,
  isFullScreen: false,
};

const BackTestingContent: React.FC = () => {
  const [state, setState] = useState<IState>(initialState);

  const handleChangeState =
    (key: keyof typeof initialState) => (value: number | boolean) => {
      setState(prev => ({ ...prev, [key]: value }));
    };

  if (state.isFullScreen) {
    return (
      <Styled.Root $isFullScreen={state.isFullScreen}>
        <Styled.ToggleFullScreen
          iconType={state.isFullScreen ? 'fullScreenExit' : 'fullScreen'}
          onClick={() => handleChangeState('isFullScreen')(!state.isFullScreen)}
        />
        <Content />
      </Styled.Root>
    );
  }

  return (
    <ExpandedContent<THeightMode>
      className='backTestingExpanded'
      height={state.height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      onUpdateHeight={handleChangeState('height')}
    >
      <Styled.ToggleFullScreen
        iconType={state.isFullScreen ? 'fullScreenExit' : 'fullScreen'}
        onClick={() => handleChangeState('isFullScreen')(!state.isFullScreen)}
      />
      <Content />
    </ExpandedContent>
  );
};

const Content: React.FC = observer(() => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const percentFinished =
    +store.pages.muliBands.backTesting.percentFinished.toFixed();

  const tabs = [
    {
      value: 'list-of-multipliers',
      label: translate('MULI_BANDS.LIST_OF_MULTIPLIERS'),
      children: <ListOfMultipliers />,
    },
    {
      value: 'list-of-trades',
      label: translate('MULI_BANDS.LIST_OF_TRADES'),
      children: <ListOfTrades />,
    },
    {
      value: 'performance-summary',
      label: translate('MULI_BANDS.PERFORMANCE_SUMMARY'),
      children: <PerformanceSummary />,
    },
  ];

  return (
    <Styled.Content>
      <Styled.Progress>
        <CircularProgressBar
          pathColor={theme.palette.primary.main}
          value={percentFinished}
          text={`${percentFinished}%`}
        />
      </Styled.Progress>
      <Tabs marginBottom='0' tabsPosition='top' tabs={tabs} />
    </Styled.Content>
  );
});

export const BackTestingContentWrapper = observer(() => {
  const store = useMst();

  const getInstrumentSpecificationAsync =
    store.entities.instruments.getInstrumentSpecificationAsync;

  const symbol = store.pages.muliBands.backTesting.getSymbol();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  useEffect(() => {
    !instrument && getInstrumentSpecificationAsync.run(symbol);
  }, [getInstrumentSpecificationAsync.inProgress]);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return <BackTestingContent />;
});
