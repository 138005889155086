/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TimeView } from '@mui/x-date-pickers/models';
import { TimePicker as TimePickerMui } from '@mui/x-date-pickers/TimePicker';

import { IChangeFieldEvent } from '@trader/types';

import { ControlInfo } from '../controlInfo';

export interface ITimepicker {
  value?: Date | null;
  helperText?: string;
  onChange?: (event: IChangeFieldEvent<Date | null>) => void;
  isError?: boolean;
  label?: string | ReactNode;
  name?: string;
  views?: TimeView[];
  shouldHideControlInfo?: boolean;
}

export const TimePicker = ({
  value,
  onChange,
  helperText,
  label,
  isError,
  shouldHideControlInfo,
  name,
  views = ['hours', 'minutes', 'seconds'],
}: ITimepicker) => {
  return (
    <Fragment>
      <TimePickerMui
        className='time-picker'
        label={label}
        views={views}
        value={value ? (dayjs(value) as any) : undefined}
        ampm={false}
        onChange={v =>
          onChange &&
          onChange({
            target: {
              name: name || '',
              value: v ? (v as unknown as Dayjs).toDate() : null,
            },
          })
        }
      />
      <ControlInfo
        isError={isError}
        helperText={helperText}
        isHide={shouldHideControlInfo}
      />
    </Fragment>
  );
};
