import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import { layoutMargin } from '@trader/constants';

export const Summary = styled(Wrapper)`
  ${() => css`
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;

    div:first-child {
      margin-left: 0;
    }
  `};
`;

export const Item = styled(Wrapper)`
  ${({ theme }) => css`
    height: 40px;
    align-items: center;
    padding: 0 ${layoutMargin}px;
    margin: 2.5px;
    border-radius: 5px;
    min-width: max-content;

    background: ${theme.palette.background.default};

    font-size: ${theme.typography.xl.fontSize};
    color: ${theme.palette.text.secondary};

    span {
      font-size: ${theme.typography.base.fontSize};
      margin-left: 10px;
    }
  `};
`;
