import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import {
  maxMultiplier,
  maxSl,
  minMultiplier,
  minQuantity,
} from '@trader/constants';
import { TInstrumentEntity, TMuliBandsStore, useMst } from '@trader/store';
import { OrderInput, Slider, Switcher } from '@trader/components';

import * as Styled from './styled';
import { useI18next } from '@trader/services';

export const Order: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands: TMuliBandsStore = store.pages.muliBands;

  const instrument =
    store.entities.instruments.get<TInstrumentEntity>(muliBands.symbol) ||
    store.entities.instruments.getAll<TInstrumentEntity>()[0];

  const changeAmount = useCallback((value: string) => {
    muliBands.runInAction(() => {
      muliBands.orderAmount = value;
    });
  }, []);

  const changeTopMultiplier = useCallback((value: number) => {
    muliBands.toggleHasMultiplierChanged(true);
    muliBands.runInAction(() => {
      muliBands.topMultiplier = value;
    });
  }, []);

  const changeBottomMultiplier = useCallback((value: number) => {
    muliBands.toggleHasMultiplierChanged(true);
    muliBands.runInAction(() => {
      muliBands.bottomMultiplier = value;
    });
  }, []);

  const changeIsStopLoss = useCallback((value: boolean) => {
    muliBands.runInAction(() => {
      muliBands.isSl = value;
    });
  }, []);

  const changeStopLoss = useCallback((value: string) => {
    muliBands.runInAction(() => {
      muliBands.sl = +value;
    });
  }, []);

  return (
    <Styled.Root>
      <OrderInput
        value={muliBands.orderAmount}
        onChange={event => changeAmount(event.target.value)}
        shouldCheckActivityOfButtons
        step={instrument.minOrderSizeIncrement}
        fixDigitAfterDot={0}
        minValue={instrument.minOrderSize}
        maxValue={instrument.maxOrderSize}
        disabled={!muliBands.id}
      />
      <Slider
        label='TOP MULTIPLIER:'
        isLabelValue
        min={minMultiplier}
        max={maxMultiplier}
        step={minMultiplier}
        value={muliBands.topMultiplier}
        onChange={changeTopMultiplier}
        disabled={!muliBands.id}
      />
      <Slider
        label='BOTTOM MULTIPLIER:'
        isLabelValue
        min={minMultiplier}
        max={maxMultiplier}
        step={minMultiplier}
        value={muliBands.bottomMultiplier}
        onChange={changeBottomMultiplier}
        disabled={!muliBands.id}
      />

      <Styled.Sl>
        <Switcher
          label={translate('COMMON.LABELS.STOP_LOSS')}
          value={muliBands.isSl}
          onChange={e => changeIsStopLoss(e.target.checked)}
        />
        {muliBands.isSl && (
          <OrderInput
            value={muliBands.sl.toString()}
            onChange={event => changeStopLoss(event.target.value)}
            shouldCheckActivityOfButtons
            step={minQuantity}
            fixDigitAfterDot={0}
            minValue={minQuantity}
            maxValue={maxSl}
            disabled={!muliBands.id}
          />
        )}
      </Styled.Sl>
    </Styled.Root>
  );
});
